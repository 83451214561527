import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuth } from 'auth/useAuth'
import { Organization } from 'core/types/User/Admin'
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { getOrganization } from 'services/public/organizations'
import { useDomain } from '../DomainProvider'

export enum OrganizationEnum {
  iSchool = 1,
  Demi = 2,
  DemiSummer = 5,
  DeciDemo = 7,
}

export const isIschool = (org?: { id: number }) => {
  return org?.id === 1
}
export function useGetOrganizationInfo() {
  const { domain, is_whitelabel } = useDomain()
  return useQuery({
    placeholderData: {
      domain,
      is_whitelabel,
    } as Organization,
    queryKey: ['organization_info', domain],
    queryFn: () => getOrganization(domain),
  })
}

const OrganizationContext = createContext<{
  org?: Organization
  onOrgChange: (orgId: number) => void
}>({} as any)

export function OrganizationProvider({ children }: { children: ReactNode }) {
  const { data: defaultOrg } = useGetOrganizationInfo()
  const { user } = useAuth()

  const [orgId, setOrgId] = useState<number>()
  const queryClient = useQueryClient()

  const onOrgChange = (orgId: number) => {
    if (user?.isAdmin) {
      localStorage.setItem('OrgId', orgId?.toString())
      setOrgId(orgId)

      queryClient.invalidateQueries([])
    }
  }

  useEffect(() => {
    if (user?.id && user.isAdmin && defaultOrg?.id) {
      let orgId: number
      let localStoareOrgId = localStorage.getItem('OrgId')

      if (localStoareOrgId) {
        orgId = +localStoareOrgId
      } else {
        orgId = user?.organizations?.[0]?.id
        localStorage.setItem('OrgId', orgId?.toString())
      }

      setOrgId(orgId)
    }

    if (user?.isStudent) {
      setOrgId(user.organization.id)
    }
  }, [user, defaultOrg?.id])

  let org = user?.isAdmin
    ? user.organizations?.find((o) => o.id === orgId)
    : user?.isStudent
    ? user.organization
    : defaultOrg

  return (
    <OrganizationContext.Provider value={{ org, onOrgChange }}>
      {children}
    </OrganizationContext.Provider>
  )
}

export function useOrganization() {
  return useContext(OrganizationContext)
}
