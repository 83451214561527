import { useQuery, useQueryClient } from '@tanstack/react-query'
import Button from 'components/Button/Button'
import { useCreateModal, useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import StudentLogsModalWindow from 'components/Modals/Admin/StudentLogsModalWindow'
import StudentProgressModal from 'components/Modals/Admin/StudentProgressModal'
import StudentScheduleModal from 'components/Modals/Admin/StudentScheduleModal'
import { QuizQuestion } from 'components/Modals/QuizModal/QuizQuestion'
import useViewPort from 'core/hooks/useViewPort'
import { Student, Tutor } from 'core/types/User'
import { QuizAnswerState, Session } from 'core/types/session'
import { Subscription } from 'core/types/subscription'
import { useOrganization } from 'utils/Providers/OrganizationProvider'

import InputField from 'components/Inputs/InputField'
import { Response } from 'core/types/Response'
import { Quiz } from 'core/types/quiz'
import Lottie from 'lottie-react'
import { Fragment, useState } from 'react'
import { toast } from 'react-toastify'
import { AdminAPI } from 'services/API'
import { QueryKeys } from 'services/QueryKeys'
import { extractResponseData } from 'services/utils'
import { Spinner } from 'utils/Spinner'
import { formatDate, formatTime } from 'utils/dateUtils'
import { errorToast } from 'utils/errorToast'
import { ResourceItem } from 'utils/usePaginatedResource'
import { MEDIA_BREAKPOINT_6L } from '../../../constants'
import no_data from '../../Icon/Animations/no_data.json'

export type AdminStudent = Student &
  ResourceItem & {
    session?: Session & { tutor: Tutor } & { is_free: boolean }
    subscription?: Subscription & { status: 'active' | 'pending' | 'expired' }
    subscription_quota_count?: number
    is_group?: boolean
    group_id?: number
    signed_up_free?: boolean
    has_projects?: boolean
  }
interface StudentTableProps {
  studentsData: AdminStudent[]
  handleEditStudent: (s: Student) => void
  isLoading: boolean
  isReadOnly: boolean
}
const STATUSCOLOR = {
  active: 'green600',
  pending: 'yellow400',
  expired: 'red500',
  canceled: 'yellow400',
}

const StudentTable = ({
  studentsData,
  isLoading,
  handleEditStudent,
  isReadOnly,
}: StudentTableProps) => {
  const { width } = useViewPort()
  // Schedule Modal
  const StudentSchedule = useCreateModal<string>()
  const { org } = useOrganization()
  const StudentLogs = useCreateModal<Student>()
  const handleStudentSchedule = () => {
    StudentSchedule.openModal()
  }

  const ViewStudentQuizModal = useCreateModal<Student>()
  // Progress Modal
  const StudentProgress = useCreateModal<string>()
  const handleStudentProgress = () => {
    StudentProgress.openModal()
  }

  const ReassignStudentModal = useCreateModal<Student>()

  return (
    <>
      <div
        className="table-responsive"
        style={{
          opacity: isLoading ? '0.5' : '1',
        }}
      >
        <table className="table table-main profile-table mt-5">
          <thead>
            <tr>
              <th
                className="text-start "
                style={{ whiteSpace: 'nowrap' }}
                scope="col"
                colSpan={1}
              >
                S-ID
              </th>
              <th className="text-start" scope="col" colSpan={3}>
                Student
              </th>
              <th className="text-start" scope="col">
                Contact
              </th>
              <th className="text-start" scope="col">
                Address
              </th>
              {(studentsData.length > 0 || width > MEDIA_BREAKPOINT_6L) && (
                <>
                  <th className="text-start" scope="col">
                    Group
                  </th>
                  <th className="text-start" scope="col">
                    Register Date
                  </th>
                  <th className="text-start" scope="col">
                    Schedule
                  </th>
                  {!org?.is_whitelabel && (
                    <th
                      className="text-start text-nowrap"
                      scope="col"
                      colSpan={4}
                    >
                      Start & End
                    </th>
                  )}
                  <th className="text-start" scope="col">
                    Class
                  </th>
                  <th className="text-start" scope="col" colSpan={3}>
                    Teacher
                  </th>
                  {!org?.is_whitelabel && (
                    <>
                      <th className="text-start" scope="col">
                        Booking
                      </th>
                      <th className="text-start" scope="col">
                        Status
                      </th>
                    </>
                  )}

                  {!isReadOnly && org?.enrollment_quiz && (
                    <>
                      <th>Status</th>
                      <th className="text-start text-nowrap" scope="col">
                        Enrollment Quiz
                      </th>
                    </>
                  )}
                  {!isReadOnly && (
                    <th className="text-start" scope="col">
                      Edit
                    </th>
                  )}
                </>
              )}
            </tr>
          </thead>

          <tbody>
            {studentsData.length > 0 ? (
              studentsData.map((data) => (
                <tr
                  key={data.id}
                  style={{
                    opacity: data.__state === 'is_updating' ? '0.5' : '1',
                  }}
                >
                  <td colSpan={1}>
                    <span className="font-color-blue600 font_14 font-normal">
                      {data.s_id}
                    </span>
                  </td>

                  <td colSpan={3} className="text-start ">
                    <span className="text-capitalize font-color-blue600 font_14 font-normal">
                      {data.name}
                    </span>
                    <span className="d-block text-capitalize font-color-blue300 font_12 font-normal">
                      {data.grade?.name}
                    </span>
                  </td>

                  <td className="text-start">
                    <span className="font-color-blue600 font_14 font-normal">
                      {data.email}
                    </span>
                    <span className="d-block font-color-blue300 font_12">
                      {data.secondary_email}
                    </span>
                    <span className="d-block font-color-blue300 font_12">
                      {data.phone}
                    </span>
                  </td>

                  <td className="text-start">
                    <span className="font-color-blue600 font_14 font-normal">
                      {data.city}
                    </span>
                    <span className="d-block font-color-blue300 font_12">
                      {data.country}
                    </span>
                  </td>

                  <td className="text-start">
                    <span className="font-color-blue600 font_14 font-normal">
                      {data?.is_group ? 'Group' : '1:1'}
                    </span>

                    {data.is_group && data.group_id && (
                      <>
                        <span className="d-block font-color-blue300  font_12 font-normal">
                          G-{data?.group_id}
                        </span>

                        <Button
                          variant="primary600"
                          style={{
                            width: '80px',
                          }}
                          className=" font_12 mt-1 mb-1 font-normal py-02 px-12 "
                          label={'Reassign'}
                          isBorderButton
                          onClick={() => ReassignStudentModal.openModal(data)}
                        />
                      </>
                    )}
                  </td>

                  <td className="text-start">
                    <span className="font-color-blue600 font_14 font-normal">
                      {formatDate(data.created_at)}
                    </span>
                    <span className="d-block font-color-blue300 font_12">
                      {data.signed_up_free ? 'Free' : 'Normal'}
                    </span>
                  </td>

                  <td className="text-start">
                    {data.pending_session_schedule && (
                      <span
                        className={`chips-border-color-yellow400 font-color-yellow400 font_12 font-normal rounded-pill py-1 px-2`}
                      >
                        Pending
                      </span>
                    )}

                    <span className="font-color-blue600 font_14 font-normal">
                      {formatDate(data.session?.start_at, 'eeee')}
                    </span>
                    <span className="d-block font-color-blue300 font_12 mb-1">
                      {formatTime(data.session?.start_at)}
                    </span>
                  </td>
                  {!org?.is_whitelabel && (
                    <td className="text-start" colSpan={4}>
                      {data.subscription && (
                        <>
                          <span className="font-color-blue600 font_14 font-normal ">
                            S:{' '}
                            {formatDate(
                              data.subscription_start_date,
                              'd MMM yyyy',
                            )}
                          </span>
                          <span className="d-block font-color-blue300 font_14">
                            E:{' '}
                            {formatDate(
                              data.subscription_end_date,
                              'd MMM yyyy',
                            )}
                          </span>
                        </>
                      )}
                    </td>
                  )}

                  <td className="text-start">
                    <span className="font-color-blue600 font_14 font-normal">
                      {data.session?.lesson.name}
                    </span>
                    <span className="d-block font-color-blue300 font_12">
                      {data.session?.level.name}
                    </span>
                    <span className="d-block font-color-blue300 font_12">
                      {data.session?.grade.name}
                    </span>
                  </td>

                  <td className="text-start" colSpan={3}>
                    {data.session?.tutor && (
                      <>
                        <span className="font-color-blue600 font_14 font-normal">
                          {data.session?.tutor?.name}
                        </span>

                        <span className="d-block font-color-blue300 text-capitalize font_12">
                          {data.session?.tutor?.t_id} {data.language}
                        </span>
                      </>
                    )}
                  </td>
                  {!org?.is_whitelabel && (
                    <>
                      <td className="text-start">
                        {data.session && (
                          <>
                            <span className="font-color-blue600 font_14 font-normal">
                              {data.session?.is_free ? 'Free' : 'Normal'}
                            </span>
                            <span className="d-block font-color-blue300 font_12">
                              {formatDate(data.session?.start_at, 'd MMM yyyy')}
                            </span>
                          </>
                        )}
                      </td>

                      <td className="text-start">
                        {data.subscription && (
                          <>
                            <span
                              className={`chips text-capitalize chips-color-${
                                STATUSCOLOR[data.subscription.status]
                              }-opacity-30 rounded-pill font_14 mb-1 w-100`}
                            >
                              {data.subscription?.status}
                            </span>

                            <span className="d-block font-color-blue300 font_12 text-center">
                              Quota: {data.subscription_quota_count}
                            </span>
                          </>
                        )}
                      </td>
                    </>
                  )}
                  {!isReadOnly && org?.enrollment_quiz && (
                    <>
                      <td>
                        <QuizEnrollmentCellStatus student={data} />
                      </td>
                      <td className="text-start ">
                        <QuizEnrollmentCell
                          student={data}
                          onClick={ViewStudentQuizModal.openModal}
                        />
                      </td>
                    </>
                  )}

                  {!isReadOnly && (
                    <td className="text-start">
                      <Button
                        variant="primary600"
                        className=" font_14 font-normal py-02 px-12"
                        label="Edit Student"
                        isBorderButton
                        onClick={() => handleEditStudent(data)}
                      />
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={30} className="text-center border-0 p-0">
                  <Lottie
                    animationData={no_data}
                    style={{ height: 130, width: 130, margin: '0 auto' }}
                  />
                  <h3 className="font_16"> No Data </h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <ReassignStudentModal.Container>
        <ReassignStudentGroupModalWindow />
      </ReassignStudentModal.Container>

      <ViewStudentQuizModal.Container>
        <ViewStudentQuizModalWindow />
      </ViewStudentQuizModal.Container>
      <StudentLogs.Container>
        <StudentLogsModalWindow />
      </StudentLogs.Container>

      <StudentSchedule.Container>
        <StudentScheduleModal />
      </StudentSchedule.Container>

      <StudentProgress.Container>
        <StudentProgressModal />
      </StudentProgress.Container>
    </>
  )
}

export function ReassignStudentGroupModalWindow() {
  const { data, closeModal } = useOpenedModal<{
    studentId: number
    slug: string
    isOffline: boolean
  }>()
  const [groupSlug, setGroupSlug] = useState(data?.slug)
  const qc = useQueryClient()
  return (
    <ModalWindow
      title={'Reassign Student Group'}
      headerClassName="gradient-header text-center"
      hasCloseBtn
      mainButtonType="button"
      mainBtn="Confirm"
      mainButtonDisabled={!groupSlug}
      mainBtnClassName="py-17 px-24 font_14"
      mainBtnOnclick={() =>
        AdminAPI.put(`/students/${data?.studentId}/groups/reassign`, {
          slug: groupSlug,
          offline: data?.isOffline,
        })
          .then(() => {
            toast.success('Student Reassigned')
            closeModal()
            qc.invalidateQueries([QueryKeys.adminStudents])
          })
          .catch(errorToast)
      }
    >
      <InputField
        label=""
        containerClassName="d-flex align-items-center"
        labelClassName="m-0"
        value={groupSlug}
        isFormInline
        onChange={(e) => setGroupSlug(e.target.value)}
      />
    </ModalWindow>
  )
}

export function QuizEnrollmentCellStatus({ student }: { student: Student }) {
  const Status = ({ status }: { status: string }) => {
    return (
      <span className="font-color-blue600 font_14 font-normal ">{status}</span>
    )
  }
  if (!student.quiz) {
    return <Status status="Pending Email Invitation" />
  }

  if (['pending', 'pending_configuration'].includes(student.quiz.status)) {
    return <Status status="Pending Quiz" />
  }

  if (student.quiz.status == 'in_progress') {
    return <Status status="Quiz In Progress" />
  }

  if (student.quiz.score === null && student.quiz.answer_start_at) {
    student.quiz['score'] = 0
    // student.quiz['answers'] = []
  }

  if (student.quiz.accepted === true) {
    return <Status status="Accepted" />
  }

  if (student.quiz.accepted === false) {
    return <Status status="Rejected" />
  }

  if (student.quiz.answer_start_at === null) {
    return <Status status="Skipped" />
  }

  if (student.quiz.score === null && student.quiz.answer_start_at) {
    return <Status status="Pending Action" />
  }
  if (student.quiz.score !== null && student.quiz.accepted === null) {
    return <Status status="Pending Action" />
  }

  return null
}
export function QuizEnrollmentCell({
  student,
  onClick,
}: {
  student: Student
  onClick: (s: Student) => void
}) {
  if (!student.quiz) {
    return null
  }

  if (student.quiz.score === null && student.quiz.answer_start_at) {
    student.quiz['score'] = 0
    // student.quiz['answers'] = []
  }

  if (student.quiz.status != 'completed') {
    return null
  }

  if (student.quiz.score === null && student.quiz.accepted === null) {
    return <AcceptRejectQuizButtons student={student} />
  }

  if (student.quiz.score !== null) {
    return (
      <div
        className="border"
        style={{
          borderRadius: '5px',
          overflow: 'hidden',
        }}
      >
        <Button
          isBorderButton
          onClick={() => onClick(student)}
          variant={'blue600'}
          className="d-block font_12 font-normal py-2 px-12 w-100 border-0 d-flex gap-4 align-items-center"
        >
          <span className="justify-self-start">
            Score: {student.quiz?.score} / {student.quiz?.questions_count}
          </span>
          {student.quiz.is_passed && (
            <i className="fa-solid fa-circle-check font-color-green500 font_14"></i>
          )}
          {/* {differenceInMinutes(
            new Date(student?.quiz?.answer_end_at || ''),
            new Date(student?.quiz?.answer_start_at || ''),
          )}{' '} */}
          {/* min */}
        </Button>
        <AcceptRejectQuizButtons student={student} />
      </div>
    )
  }
  return null
}

export function AcceptRejectQuizButtons({ student }: { student: Student }) {
  const queryClient = useQueryClient()
  const modal = useOpenedModal()

  const onSubmit = () => {
    queryClient.invalidateQueries([QueryKeys.adminStudents])
    modal?.closeModal()
  }
  return (
    <div className="d-flex gap-1  font-normal font_12">
      {student.quiz?.accepted !== true && (
        <Button
          isBorderButton
          variant="green500"
          className=" font_14 flex-1 font-normal py-2 px-12 border-0"
          onClick={() =>
            AdminAPI.put(`students/${student.id}/quiz_status`, {
              accepted: true,
            })
              .then(onSubmit)
              .catch(errorToast)
          }
        >
          Accept
        </Button>
      )}

      {student.quiz?.accepted !== false && (
        <Button
          isBorderButton
          variant="red500"
          className=" font_14  flex-1 font-normal py-02 px-12 border-0"
          onClick={() =>
            AdminAPI.put(`students/${student.id}/quiz_status`, {
              accepted: false,
            })
              .then(onSubmit)
              .catch(errorToast)
          }
        >
          Reject
        </Button>
      )}
    </div>
  )
}

export function ViewStudentQuizModalWindow() {
  const { data: student } = useOpenedModal<Student>()

  const { data: quiz } = useQuery({
    keepPreviousData: false,
    queryFn: () =>
      AdminAPI.get<Response<Quiz>>(`/quizzes/${student?.quiz?.id}`).then(
        extractResponseData,
      ),
    enabled: !!student?.quiz?.id,
    queryKey: ['quizzes', student?.quiz?.id],
  })

  if (!student || !quiz) return null

  return (
    <ModalWindow
      title={<>{student?.name}</>}
      headerClassName="gradient-header text-center"
      hasCloseBtn
      maxWidth={1132}
      mainBtnClassName="py-17 px-71 font_24 my-4"
    >
      {!quiz && <Spinner />}
      <div className="d-flex justify-content-between mb-4 font_14 w-100">
        <div className=" font-color-300">
          <span>{student?.grade?.name} Enrollment Quiz</span>

          <span className="d-block ">
            Date: {formatDate(quiz?.start_at, 'dd MMM yyy')}
          </span>
          <span className="d-block">Time: {formatTime(quiz?.start_at)}</span>
          <span className="d-block font-color-blue300">
            Duration: {quiz?.duration} min
          </span>
        </div>

        <div className="ml-auto">
          <QuizEnrollmentCell onClick={() => {}} student={student} />
        </div>
      </div>

      <ViewQuizSubmission {...quiz} />
    </ModalWindow>
  )
}
export default StudentTable

export function ViewQuizSubmission(quiz: {
  questions: Quiz['questions']
  answers: Quiz['answers']
}) {
  return (
    <div className="">
      <div className="d-grid gap-3">
        {quiz?.questions.map((q, index) => {
          const studentAnswer = quiz?.answers?.find(
            (a) => a.id === q.id,
          )?.answer
          const correctAnswer = q.correct_answer
          const correctProps =
            studentAnswer === correctAnswer
              ? {
                  style: {
                    backgroundColor: '#0aba9830',
                  },
                }
              : {}

          return (
            <Fragment key={q.id}>
              <div className="font-color-blue600 bg-white shadow-sm rounded">
                <div className="answer-show">
                  <QuizQuestion
                    id={q.id}
                    image={q.image}
                    questionText={
                      <div>
                        <div className="border-b border-color-grey-opacity-10 p-2">
                          Question #{q.id}
                        </div>
                        <div className="d-flex gap-2 px-4 align-items-center">
                          <div className="w-100 px-2 py-2 font-color-blue300 font-semibold">
                            <div>{q.question.en}</div>
                            <div className="text-right">{q.question.ar}</div>
                          </div>
                        </div>
                      </div>
                    }
                    answers={q.answers
                      .filter((_) => !!_)
                      .map((answer) => {
                        let value = answer
                        let text = (
                          <div>
                            <div>{answer.en}</div>
                            <div className="text-right">{answer.ar}</div>
                          </div>
                        )
                        let state: QuizAnswerState = ''
                        if (
                          studentAnswer?.en !== answer.en &&
                          correctAnswer?.en !== answer.en
                        ) {
                          state = ''
                        }
                        if (
                          studentAnswer?.en === answer.en &&
                          answer.en === correctAnswer?.en
                        ) {
                          state = 'success'
                        }

                        if (
                          studentAnswer?.en === answer.en &&
                          answer.en !== correctAnswer?.en
                        ) {
                          state = 'fail'
                        }

                        if (
                          studentAnswer?.en !== correctAnswer?.en &&
                          answer.en === correctAnswer?.en
                        ) {
                          state = 'correction'
                        }

                        return {
                          value,
                          text,
                          state,
                        }
                      })}
                    value={studentAnswer}
                    onChange={() => {}}
                  />

                  {(q.hint?.en || q.hint?.ar) && (
                    <div className="px-3 py-2 font-semibold font-color-orange500">
                      <div>{q.hint?.en}</div>
                      <div className="text-right">{q.hint?.ar}</div>
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}
