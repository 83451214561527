import { useMutation } from '@tanstack/react-query'
import { useLoggedInStudent } from 'auth/useAuth'
import Button from 'components/Button/Button'
import { usePreviewModal } from 'components/Inputs/FilePreviewInput'
import InputField from 'components/Inputs/InputField'
import { useCreateModal, useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import { FormikProvider, useFormik } from 'formik'
import { toast } from 'react-toastify'
import { downloadCertificate } from 'services/student/certificate'
import { updateProfile } from 'services/student/profile'
import * as yup from 'yup'

export function EnglishNameStudentModal() {
  const { loggedInStudent } = useLoggedInStudent()

  const Modal = useCreateModal({
    isOpened: loggedInStudent.pending_certificate,
    disableBackdrop: true,
  })

  return (
    <Modal.Container>
      <EnglishNameStudentModalWindow />
    </Modal.Container>
  )
}

export function EnglishNameStudentModalWindow() {
  return (
    <div>
      <ModalWindow
        titleClassName="font-semibold"
        maxWidth={700}
        borderRadius={20}
        titleFontSize={18}
        title={
          <div className="text-end d-flex align-items-baseline gap-2" dir="rtl">
            <i className={`fa-solid fa-bullhorn font-color-orange500`} />
            <div>
              {'تهانينا!'}
              <br />
              {
                'تم اجتياز المستوى (الأول) من مبادرة براعم مصر الرقمية، الدفعة الأولى سبتمبر ٢٠٢٤ وحان وقت اصدار شهادة التخرج الخاصة بالمستوى.'
              }
            </div>
          </div>
        }
        containerClassName="text-center"
      >
        <EnglishNameStudentsQuestionModal />
      </ModalWindow>
    </div>
  )
}

const validationSchema = yup.object({
  firstName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, 'يجب أن يحتوي الاسم الأول على حروف إنجليزية فقط')
    .max(15, 'يجب ألا يزيد الاسم الأول عن 15 حرفًا')
    .required('الاسم الأول مطلوب'),

  middleName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, 'يجب أن يحتوي الاسم الثاني على حروف إنجليزية فقط')
    .max(15, 'يجب ألا يزيد الاسم الثاني عن 15 حرفًا')
    .required('الاسم الثاني مطلوب'),

  lastName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, 'يجب أن يحتوي الاسم الثالث على حروف إنجليزية فقط')
    .max(15, 'يجب ألا يزيد الاسم الثالث عن 15 حرفًا')
    .required('الاسم الثالث مطلوب'),

  familyName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, 'يجب أن يحتوي الاسم الرابع على حروف إنجليزية فقط')
    .max(15, 'يجب ألا يزيد الاسم الرابع عن 15 حرفًا')
    .required('الاسم الرابع مطلوب'),
})

export function useDownloadCertificate(CertificateModal: any) {
  const mutation = useMutation(async () => {
    CertificateModal.openModal()
    const certificate = await downloadCertificate()
    const url = window.URL.createObjectURL(certificate)
    CertificateModal.setData({
      previewUrl: url,
      downloadImageName: `Certificate.png`,
      type: 'image',
    })
  })

  return { download: mutation.mutate, loading: mutation.isLoading }
}

export function EnglishNameStudentsQuestionModal() {
  const { closeModal } = useOpenedModal()
  const CertificateModal = usePreviewModal()
  const { loggedInStudent, loadProfile } = useLoggedInStudent()
  const { download: downloadCertificate, loading } =
    useDownloadCertificate(CertificateModal)

  const nameParts = (loggedInStudent.name_en || '').split(' ')
  const [firstName = '', middleName = '', lastName = '', familyName = ''] =
    nameParts

  const formik = useFormik({
    initialValues: {
      firstName,
      middleName,
      lastName,
      familyName,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        const fullName =
          `${values.firstName} ${values.middleName} ${values.lastName} ${values.familyName}`.trim()

        await updateProfile({
          name_en: fullName,
        })
        await downloadCertificate()
        await loadProfile()
        closeModal()
      } catch (e: any) {
        toast.error(e?.response?.data?.message || 'An error occurred')
        closeModal()
      }
    },
  })

  return (
    <div className="mt-12">
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-2">
            <label>
              .رجاء تأكيد الأسم الرباعي باللغة الإنجليزية لتتمكن من تحميل شهادة
              التخرج
            </label>
            <div className="d-flex flex-column gap-2 my-3">
              <InputField
                label="First Name"
                labelClassName="mb-4 float-start"
                containerClassName="w-100"
                value={formik.values.firstName}
                name="firstName"
                onChange={formik.handleChange}
                error={formik.errors.firstName}
                touched={formik.touched.firstName}
              />
              <InputField
                label="Second Name"
                labelClassName="mb-4 float-start"
                containerClassName="w-100"
                value={formik.values.middleName}
                name="middleName"
                onChange={formik.handleChange}
                error={formik.errors.middleName}
                touched={formik.touched.middleName}
              />
              <InputField
                label="Third Name"
                labelClassName="mb-4 float-start"
                containerClassName="w-100"
                value={formik.values.lastName}
                name="lastName"
                onChange={formik.handleChange}
                error={formik.errors.lastName}
                touched={formik.touched.lastName}
              />
              <InputField
                label="Fourth Name"
                labelClassName="mb-4 float-start"
                containerClassName="w-100"
                value={formik.values.familyName}
                name="familyName"
                onChange={formik.handleChange}
                error={formik.errors.familyName}
                touched={formik.touched.familyName}
              />
            </div>
          </div>
          <div className="alert alert-warning mt-4">
            .إذا قمت بتأكيد الاسم، فلا يمكنك تعديله لاحقًا
          </div>
          <Button
            type="submit"
            className="mt-4 font_14 rounded-pill d-inline-flex"
            disabled={loading}
          >
            Confirm
          </Button>
        </form>
      </FormikProvider>
    </div>
  )
}
