import { WhiteLabelLogo } from 'layout/StudentDashboard/StudentDesktopLayout'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
} from 'react'
import {
  OrganizationEnum,
  useOrganization,
} from 'utils/Providers/OrganizationProvider'
import { CollapseExpandButton } from './CollapseExpandButton'
import LogoutButton from './LogoutButton'

export const SideBarContext = createContext<{
  collapsed: boolean
  setCollapsed: Dispatch<SetStateAction<boolean>>
}>({} as any)

export function useShowWhiteLabelFirst() {
  const { org } = useOrganization()
  return (
    org &&
    [
      OrganizationEnum.Demi,
      OrganizationEnum.DemiSummer,
      OrganizationEnum.DeciDemo,
      // TODO add real deci org when created production
    ].includes(org?.id)
  )
}
export function NavSideBar({ children }: { children: ReactNode }) {
  const { collapsed } = useContext(SideBarContext)
  const showWhiteLabelLogoFirst = useShowWhiteLabelFirst()

  return (
    <div
      className={`flex-scroll border  border-grey-opacity-10 shadow-sm  sidebar ${
        collapsed ? 'collapsed' : ''
      }`}
    >
      <div
        className="d-flex justify-content-between align-items-center py-2"
        style={{ width: '100%' }}
      >
        {!showWhiteLabelLogoFirst && (
          <img
            className="logo-big"
            style={{ width: '60%', objectFit: 'fill' }}
            alt=""
            src={`/images/logo/app/en-a.png`}
          />
        )}
        <CollapseExpandButton />
      </div>

      {showWhiteLabelLogoFirst ? (
        <div className="d-flex flex-column align-items-center justify-content-center mb-4 mt-1 gap-3">
          <WhiteLabelLogo style={{ width: '100%' }} />
          <img
            className="logo-big "
            style={{ width: '80%', objectFit: 'fill' }}
            alt=""
            src={`/images/logo/app/en-a.png`}
          />
        </div>
      ) : (
        <WhiteLabelLogo />
      )}

      {children}

      <div className="border-b my-2 border-color-grey-opacity-10" />

      <LogoutButton />
    </div>
  )
}
