import { CustomFilter } from 'components/Query/filter'
import { Select } from 'components/Select/Select'
import ActiveInactiveSelect from 'components/Selectors/ActiveInactiveSelect'
import EmploymentTypeSelect from 'components/Selectors/EmploymentTypeSelect'
import LanguageSelect from 'components/Selectors/LanguageSelect'
import PlanSelect from 'components/Selectors/PlanSelect'
import TutorStatusSelect from 'components/Selectors/TutorStatusSelect'
import { AdminRoleSelect } from 'components/Selectors/users/RoleSelect'
import { ReactNode } from 'react'
import { months, years } from 'utils/dateUtils'
import { BooleanSelectFilter } from '../Basic/BooleanSelectFilter'
import { SelectFilter } from '../Basic/SelectFilter'

export function LabelWithIcon({
  faIcon,
  children,
}: {
  faIcon: string
  children: ReactNode
}) {
  return (
    <span className={`d-flex gap-1 align-items-baseline`}>
      <i
        className={`${faIcon} font-color-blue300 font_12`}
        style={{ width: '20px' }}
      />
      {children}
    </span>
  )
}

export const OnlineOfflineFilter: BooleanSelectFilter = {
  type: 'boolean_select',
  label: 'Online / Offline',
  clearable: false,
  pinned: true,
  labels: {
    yes: (
      <LabelWithIcon faIcon="fa-solid fa-chalkboard-user" children="Offline" />
    ),
    no: <LabelWithIcon faIcon="fa-solid fa-headphones" children="Online" />,
  },
}

export const FreePaidFilter: BooleanSelectFilter = {
  type: 'boolean_select',
  clearable: true,
  label: 'Free / Paid',
  labels: { yes: 'Free', no: 'Paid' },
}

export const AvailabilityFilter: BooleanSelectFilter = {
  type: 'boolean_select',
  clearable: true,
  label: 'Availability',
  labels: { yes: 'Available', no: 'Full' },
}

export const FeedbackFilter: SelectFilter = {
  type: 'select',
  label: 'Feedback',
  options: [
    { label: 'Waiting', value: 'waiting' },
    { label: 'Done', value: 'done' },
    { label: 'Late', value: 'late' },
  ],
}

export const StudentAbsentFilter: BooleanSelectFilter = {
  label: 'Attended / Absent',
  type: 'boolean_select',
  labels: {
    no: <LabelWithIcon faIcon="fa-solid fa-check" children="Attended" />,
    yes: <LabelWithIcon faIcon="fa-solid fa-times" children="Absent" />,
  },
}

export const TutorJoinTimeFilter: SelectFilter = {
  type: 'select',
  label: 'Tutor Join Time',
  options: [
    { label: 'Late', value: 'late' },
    { label: 'On Time', value: 'on_time' },
    { label: 'Early', value: 'early' },
    { label: 'Missed', value: 'missed' },
    { label: 'Joined', value: 'joined' },
  ],
}

export const RenewalTypeFilter: SelectFilter = {
  type: 'select',
  label: 'Renewal Type',
  options: [
    { label: 'New Plan', value: 'new_plan' },
    { label: 'Upgrade Plan', value: 'upgrade_plan' },
  ],
}

export const PaymentMethodFilter: SelectFilter = {
  type: 'select',
  label: 'Payment Method',
  options: [
    { label: 'Online Card', value: 'online_card' },
    { label: 'Bank Installment', value: 'bank_installment' },
    { label: 'Mobile Wallet', value: 'mobile_wallet' },
    { label: 'Offline', value: 'offline' },
    { label: 'Fawry', value: 'fawry' },
  ],
}
export const OrderStatusFilter: SelectFilter = {
  type: 'select',
  label: 'Status',
  options: [
    { label: 'Draft', value: 'draft' },
    { label: 'Completed', value: 'completed' },
  ],
}

export const OrderPaymentProviderFilter: SelectFilter = {
  type: 'select',
  label: 'Payment Provider',
  options: [
    { label: 'Stripe', value: 'stripe' },
    { label: 'Kashier', value: 'kashier' },
    { label: 'Paymob', value: 'paymob' },
    { label: 'Manual', value: 'manual' },
  ],
}
export const CurrenciesFilter: SelectFilter = {
  multi: true,
  type: 'select',
  label: 'Currency',
  options: [
    { label: 'EGP', value: 'EGP' },
    { label: 'USD', value: 'USD' },
    { label: 'AED', value: 'AED' },
    { label: 'SAR', value: 'SAR' },
    { label: 'BHD', value: 'BHD' },
    { label: 'QAR', value: 'QAR' },
    { label: 'KWD', value: 'KWD' },
    { label: 'EUR', value: 'EUR' },
    { label: 'OMR', value: 'OMR' },
  ],
}

export const LanguageFilter: CustomFilter<string | undefined> = {
  type: 'custom',
  element: (p) => <LanguageSelect {...p} />,
}

export const EmploymentFilter: CustomFilter<string | undefined> = {
  type: 'custom',
  element: (p) => <EmploymentTypeSelect {...p} />,
}

export const TutorStatusFilter: CustomFilter<string | undefined> = {
  type: 'custom',
  element: (p) => <TutorStatusSelect {...p} />,
}

export const ActiveInactiveFilter: CustomFilter<boolean | undefined> = {
  type: 'custom',
  element: (p) => <ActiveInactiveSelect {...p} />,
}

export const YearSelectFilter: SelectFilter = {
  type: 'select',
  label: 'Year',
  options: years,
}

export const MonthSelectFilter: SelectFilter = {
  type: 'select',
  label: 'Month',
  options: months.map((label, index) => ({ label, value: index + 1 })),
}

export const DisabledSelectFilter: CustomFilter<number> = {
  type: 'custom',
  element: (ctx) => <Select disabled />,
}

export const PlanSelectFilter: CustomFilter<number | undefined> = {
  type: 'custom',
  element: (ctx) => <PlanSelect {...ctx} />,
}

export const AdminRoleSelectFilter: CustomFilter<number | undefined> = {
  type: 'custom',
  element: (ctx) => <AdminRoleSelect {...ctx} label="Role" />,
}

export const TutorTagsFilter: SelectFilter = {
  type: 'select',
  label: 'Tags',
  options: [
    { label: 'Cover', value: 'cover' },
    { label: 'Recommend', value: 'recommend' },
    { label: 'Under Evaluation', value: 'under-evaluation' },
    { label: 'Top Performer', value: 'top-performer' },
    { label: 'Blacklist', value: 'blacklist' },
    { label: 'Out', value: 'out' },
    { label: 'Terminated', value: 'terminated' },
    { label: 'Student', value: 'student' },
  ],
}
