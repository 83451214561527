import { AdminStudent } from 'components/Tables/Admin/StudentTable'
import { PaginatedResponse, Response } from 'core/types/Response'
import { Student } from 'core/types/User'
import { AdminAPI } from 'services/API'
import {
  CommonParams,
  Optional,
  extractResponseData,
  pgQueryString,
} from 'services/utils'

export type QuizStatus = 'accepted' | 'rejected' | 'pending' | 'skipped'

export type StudentParams = CommonParams & {
  has_projects?: boolean
  status?: 'completed' | 'upcoming' | 'today'
  subscription_status?: 'canceled' | 'active' | 'pending' | 'expired' | 'free'
  should_upgrade?: boolean
  keyword?: string
  // start_at?: DateRange
  created_at_from?: Optional<Date>
  create_at_to?: Optional<Date>
  lesson?: number
  level?: number
  grade?: number
  start_at_day?: number
  start_at_hour?: Optional<Date>
  roadMapId?: number
  sign_up_at_from?: Optional<Date>
  sign_up_at_to?: Optional<Date>
  slot_id?: number
  is_booking_free?: boolean
  tutor_id?: number
  is_group?: boolean
  active?: boolean
  is_pending_schedule?: boolean
  quiz_status?: string
  quiz_score?: number
  sales_owner_id?: number
  language?: string
  infobip_connected?: boolean
  lost_reason?: string
  subscription_retained?: boolean
  subscription_period_end_month?: number
  subscription_period_end_year?: number
  sessions_per_week_count?: number
  retention_owner_id?: number
}

export type StudentFilter = CommonParams & {
  is_group?: boolean
  sign_up_at_from?: Optional<Date>
  sign_up_at_to?: Optional<Date>
  slot_id?: number
  is_pending_schedule?: boolean
  Grade?: number
  Level?: number
  Lesson?: number
  tutor_id?: number
  is_booking_free?: boolean
  language?: string
}

export function getStudentsById(
  id: number,
  minimal?: boolean,
): Promise<AdminStudent> {
  return AdminAPI.get<Response<AdminStudent>>(
    `students/${id}${minimal ? '?minimal=true' : ''}`,
  ).then((r) => r.data.data)
}

export function getStudents(
  params: StudentParams,
): Promise<PaginatedResponse<AdminStudent>> {
  return AdminAPI.get<PaginatedResponse<AdminStudent>>(
    `students${pgQueryString(params)}`,
  ).then((r) => r.data)
}

export function updateStudent(student: Partial<Student>) {
  return AdminAPI.put<Response<Student>>(
    `students/${student.id}`,
    student,
  ).then(extractResponseData)
}

export function createStudent(student: Partial<Student>) {
  return AdminAPI.post<Response<Student>>(`students`, student).then(
    extractResponseData,
  )
}

export function getStudentActivites({
  student_id,
  ...params
}: CommonParams & { student_id: number }) {
  return AdminAPI.get<
    PaginatedResponse<{ id: number; log: string; created_at: string }>
  >(`students/${student_id}/activities${pgQueryString(params)}`).then(
    (r) => r.data,
  )
}

export function importStudents(studentsFile: FormData) {
  return AdminAPI.post<Response<any>>('students/import', studentsFile, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(extractResponseData)
}

export type FileImportStatus = {
  success_count: number
  errors_count: number
  error_messages: string[]
  status: 'completed' | 'in_progress'
  file_url: string
  created_at: string
  updated_at: string
}

export function importStudentsStatus() {
  return AdminAPI.get<PaginatedResponse<FileImportStatus>>(
    'students/import_status?per_page=3',
  ).then(extractResponseData)
}

export function acceptstudentsQuiz(params: StudentParams) {
  return AdminAPI.put(`students/quiz_status_bulk${pgQueryString(params)}`, {
    accepted: true,
  })
}

export function rejectStudentsQuiz(params: StudentParams) {
  return AdminAPI.put(`students/quiz_status_bulk${pgQueryString(params)}`, {
    accepted: false,
  })
}

export function resetStudentsQuiz(params: StudentParams) {
  return AdminAPI.put(`students/quiz_status_bulk${pgQueryString(params)}`, {
    accepted: '',
  })
}

export function activateStudents(params: StudentParams) {
  return AdminAPI.put(`students/bulk${pgQueryString(params)}`, {
    active: true,
  })
}

export function deactivateStudents(params: StudentParams) {
  return AdminAPI.put(`students/bulk${pgQueryString(params)}`, {
    active: false,
  })
}
