import { Feedback } from '.'
import { Admin, Student, Tutor } from './User'

type Grade = {
  id: number
  name: string
}

type Level = {
  id: number
  name: string
}

export type QuizQuestionAnswer = {
  id: number
  answer: i18n
  correct: boolean
  answerd_at: string
  correct_answer: i18n
}

export type QuizAnswer = string

export type i18n = {
  ar?: string
  en?: string
}

export const emptyI18n = () => ({
  ar: '',
  en: '',
})
export type AdminQuizQuestion = {
  id: number
  answers: i18n[]
  category?: string
  image?: string
  question: i18n
  hint?: i18n
  correct_answer?: i18n
  collapsed?: boolean
}

export type QuizQuesiton = {
  id: number
  answers: i18n[]
  category?: string
  image?: string
  question: i18n
  correct_answer?: i18n
  collapsed?: boolean
  hint?: i18n
}

export type QuizAnswerState = '' | 'fail' | 'success' | 'correction'

export type AdminFeedback = { ar: string; en: string }

export type AdminLesson = {
  id: number
  name: string
  description: string
  recap_video: File
  resources: File
  resources_url: string

  homework_url: string
  revision_url: string
  homework: File
  revision: File
  recap_video_url: string
  slides_url?: string
  manual_url?: string
  project_url?: string
  recap_video_vdocipher_id_ar: string
  recap_video_vdocipher_id_en: string
  feedback: AdminFeedback[]
  topics: Topic[]
  section_en: string
  section_ar: string
}
export type Topic = {
  id: number
  name_i18n: i18n
  description: string
  description_ar: string
  description_en: string
  name: string
  sub_section?: string
  sub_section_en?: string
  sub_section_ar?: string
  name_ar: string
  name_en: string
  plasmic_component: string
  content_type: TopicContentType
  position: number
  resources_url: string
  video_vdocipher_id: string
  video_vdocipher_id_en: string
  video_vdocipher_id_ar: string
  questions: AdminQuizQuestion[] | null
  section?: string
  section_en?: string
  section_ar?: string
  path: string
}

export type Lesson = {
  id: number
  name: string
  description: string
  position: number
  section: string
  section_ar: string
  section_en: string
  recap_video?: File
  resources?: File
  resources_url: string
  homework_url: string
  revision_url: string
  recap_video_url: string
  slides_url?: string
  manual_url?: string
  questions: AdminQuizQuestion[]
  recap_video_vdocipher_id: string
  recap_video_vdocipher_id_ar: string
  recap_video_vdocipher_id_en: string
  feedback: AdminFeedback[]
}

export interface SessionTopicLog {
  id: number
  completed_at: string
  allow_retake_quiz: boolean
  locked: boolean
  position: number
  study_time_seconds: number
  questions: AdminQuizQuestion[]
  answers: QuizQuestionAnswer[] | null
  activity_answer: string
  quiz_trials_count: number
  quiz_result?: {
    questions_count: number
    answers_count: number
    correct_count: number
  }
}

export interface SessionTopic {
  dotPath: string
  parent?: string
  id: number
  name: string
  name_en: string
  name_ar: string
  position: number
  resources_url: string
  section: string
  section_ar: string
  section_en: string

  sub_section: string
  sub_section_ar: string
  sub_section_en: string
  nestedPositionId?: string
  content_type: TopicContentType
  description: string
  description_en: string
  description_ar: string
  plasmic_component: string
  video_vdocipher_id: string
  video_vdocipher_id_en: string
  video_vdocipher_id_ar: string
  questions: QuizQuesiton[] | null
}

export interface ListTopic extends SessionTopic {
  listNumber: string
  hasSubsection: boolean
}

export type Session = {
  id: number
  is_upcoming?: boolean
  position: number
  is_locked?: boolean
  completed_topics_count: number
  study_time_seconds: number
  start_at: string
  current_time: string
  end_at: string
  is_offline: boolean
  answers: null | QuizQuestionAnswer[]
  tutor_feedback: null // ?
  student_feedback: Feedback[] | null
  student_feedback_comment: string
  is_student_absent: boolean
  feedback_score: number
  feedback_status: string
  internal_feedback_status: string
  internal_feedback: string
  lesson: Lesson
  level: Level
  grade: Grade
  hide_quiz_results: boolean | false
  meeting_url: string
  location: {
    name: string
    address: string
    map_url: string
  }
  quiz_result: QuizResult
  slot_id: number

  next_session_id?: number | null
  prev_session_id?: number | null
  topics: TopicSummary[]
}
export type TopicContentType =
  | 'video'
  | 'quiz'
  | 'reading'
  | 'knowledge_check_quiz'
  | 'activity'
  | 'plasmic'

export type TopicSummary = {
  id: number
  name: string
  position: number
  content_type: TopicContentType
  section: null
  sub_section: null
  topic_log: SessionTopicLog
}

export type ModifiedTopicSummary = TopicSummary & {
  dotPath: string
  parent: string
}

export type QuizResult = {
  answers_count: number
  correct_count: number
  questions_count: number
}

export function getQuizResultState(quiz_result: QuizResult) {
  const hasQuestions = quiz_result.questions_count > 0
  const inProgress =
    quiz_result.answers_count > 0 &&
    quiz_result.answers_count < quiz_result.questions_count
  const isCompleted =
    quiz_result.answers_count == quiz_result.questions_count && hasQuestions
  const notStarted = hasQuestions && quiz_result.answers_count == 0

  const score = `${quiz_result.correct_count} / ${quiz_result.questions_count}`

  const isAllCorrect = quiz_result.correct_count === quiz_result.questions_count

  return {
    isAllCorrect,
    hasQuestions,
    inProgress,
    isCompleted,
    notStarted,
    score,
  }
}

export type AdminSession = Session & {
  lesson: AdminLesson
  student: Student
  tutor: Tutor
  is_free: boolean
  join_time: string
  student_join_time: string
  tutor_join_time: string
  slot_id: number
  status: 'active' | 'frozen' | 'canceled'
  freeze_notes: string | null
  freezed_at: string | null
  in_payroll: boolean
  in_freeze_quote: boolean
  group: {
    id: number
    slug: string
  }
  freezed_by: {
    id: number
    name: string
  } | null
}

export type AdminGroupSession = {
  id: number
  start_at: string
  end_at: string
  slot_id: number
  status: 'active' | 'frozen' | 'canceled'
  freeze_notes: null
  freezed_at: null
  in_payroll: true
  start_date?: string
  tutor_join_time?: string
  location: {
    name: string
    address: string
  }
  group: {
    id: number
    slug: string
    start_at?: string
    students_capacity: number
    students_count: number
  }
  lesson: {
    id: number
    name: string
    description: string
    slides_url: string
    manual_url: string
    questions: QuizQuesiton[]

    feedback: string[]
  }
  level: {
    id: number
    name: string
  }
  grade: {
    id: number
    name: string
  }
  freezed_by?: Admin
  tutor?: Tutor
  second_tutor?: Tutor
}
