import { useLoggedInStudent } from 'auth/useAuth'
import Button from 'components/Button/Button'
import { useCreateModal, useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import { BooleanSelect } from 'components/Selectors/base/BooleanSelect'
import { FormikProvider, useFormik } from 'formik'
import { toast } from 'react-toastify'
import { updateProfile } from 'services/student/profile'
import * as yup from 'yup'

export function StudentCollectLocationModal() {
  const { loggedInStudent } = useLoggedInStudent()

  const Modal = useCreateModal({
    isOpened:
      loggedInStudent.pending_survery_info &&
      loggedInStudent.form_interested_next_level == null,
  })

  return (
    <Modal.Container>
      <StudentCollectLocationModalWindow />
    </Modal.Container>
  )
}
export function StudentCollectLocationModalWindow() {
  return (
    <div dir="rtl">
      <ModalWindow
        titleClassName="font-semibold "
        maxWidth={700}
        borderRadius={20}
        titleFontSize={18}
        hasCloseBtn
        title={
          <div>
            <div className="text-start d-flex align-items-baseline gap-2 ">
              <i className={`fa-solid fa-bullhorn font-color-orange500`} />
              <div>{`استطلاع رأي الالتحاق بدفعة أكتوبر 2024`}</div>
            </div>
          </div>
        }
        containerClassName="text-center"
      >
        <LocationGradeQuestionsModal />
      </ModalWindow>
    </div>
  )
}

const validationSchema = yup.object({
  question1: yup.string().required('Question 1 is required'),
})

export function LocationGradeQuestionsModal() {
  const { closeModal } = useOpenedModal()
  const { loggedInStudent, loadProfile } = useLoggedInStudent()
  const getLabelForGrade = (gradeId: number) => {
    switch (gradeId) {
      // 'Label for Grade 4'
      case 22:
        return 'يرجى الإجابة على السؤال التالي بشكل مبدئي، في حالة النجاح في المستوى التدريبي الأول (الصف الرابع الابتدائي)، هل تودون الإلتحاق بالمستوى التدريبي الثاني (الصف الخامس الابتدائي) في مبادرة براعم مصر الرقمية (دفعة أكتوبر 2024) ؟'
      // 'Label for Grade 5'
      case 23:
        return 'يرجى الإجابة على السؤال التالي بشكل مبدئي، في حالة النجاح في المستوى التدريبي الثاني (الصف الخامس الابتدائي)، هل تودون الإلتحاق بالمستوى التدريبي الثالث (الصف السادس الابتدائي) في مبادرة براعم مصر الرقمية (دفعة أكتوبر 2024) ؟'
      //'Label for Grade 6'
      case 24:
        return 'يرجى الإجابة على السؤال التالي بشكل مبدئي، في حالة النجاح في المستوى التدريبي الثالث (الصف السادس الابتدائي)، هل تودون الإلتحاق بالمستوى التدريبي الأول (الصف الأول الإعدادي) في مبادرة أشبال مصر الرقمية (دفعة أكتوبر 2024) ؟'
      default:
        return ''
    }
  }
  const gradeLabel = getLabelForGrade(loggedInStudent.grade.id)
  const formik = useFormik({
    initialValues: {
      question1: loggedInStudent.form_interested_next_level,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        await updateProfile({
          form_interested_next_level: values.question1,
        })
        loadProfile()
        closeModal()
      } catch (e: any) {
        toast.error(e?.response?.data?.message || 'An error occurred')
        closeModal()
      }
    },
  })

  return (
    <div className="mt-12 ">
      <div className="text-end d-flex align-items-baseline">
        <span>{gradeLabel}</span>
      </div>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className=" mt-2 ">
            <div className="d-flex gap-4 my-3">
              <BooleanSelect
                label="اختار"
                value={formik.values.question1}
                onChange={(e) => formik.setFieldValue('question1', e)}
                labels={{
                  yes: 'نعم',
                  no: 'لا',
                }}
              />
            </div>
          </div>
          <Button
            type="submit"
            className="mt-4 font_14 rounded-pill d-inline-flex"
            disabled={formik.values.question1 === undefined}
          >
            تاكيد
          </Button>
        </form>
      </FormikProvider>
    </div>
  )
}
