import * as Sentry from '@sentry/react'
import { RequireAuth } from 'auth/RequireAuth'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import PageNotFound from 'components/ErrorBoundary/PageNotFound'
import { QuickLogin } from 'components/QuickLogin'
import AdminDashboard from 'layout/AdminDashboard'
import StudentDashboard from 'layout/StudentDashboard/StudentDashboard'
import TutorDashboard from 'layout/TutorDashboard/TutorDashboard'
import { useEffect } from 'react'
import { Outlet, createBrowserRouter, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import UserChannelProvider from 'socket/UserChannelProvider'
import { LanguageProvider } from 'utils/LanguageProvider'
import { ChatProvider } from 'utils/Providers/ChatProvider'
import HotjarProvider from 'utils/Providers/HotjarProvider'
import { useOverlaySpinner } from 'utils/SpinnerOverlayProvider'
import { isProduction } from 'utils/helpers'
import { AdminRoutes } from './AdminRoutes'
import { MentorRoutes } from './MentorRoutes'
import { OpenRoutes } from './OpenRoutes'
import { PublicRoutes } from './PublicRoutes'
import { StudentRoutes } from './StudentRoutes'
import { TutorRoutes } from './TutorRoutes'
import { PlasmicCanvasHost } from '@plasmicapp/loader-react'

const routes = [
  {
    element: (
      <RequireAuth allowed={['guest', 'Student', 'Tutor', 'Mentor', 'Admin']} />
    ),
    children: [
      {
        element: <Outlet />,
        children: OpenRoutes,
      },
    ],
  },
  {
    element: <RequireAuth allowed={['guest']} />,
    children: [
      {
        element: <Outlet />,
        children: PublicRoutes,
      },
    ],
  },
  {
    path: 'mentor',
    element: <RequireAuth allowed={['Mentor']} />,
    children: [
      {
        element: (
          <UserChannelProvider>
            <TutorDashboard />
          </UserChannelProvider>
        ),
        children: MentorRoutes,
        ErrorBoundary,
      },
    ],
  },
  {
    path: 'student',
    element: <RequireAuth allowed={['Student']} />,
    children: [
      {
        element: (
          <UserChannelProvider>
            <StudentDashboard />
          </UserChannelProvider>
        ),
        children: StudentRoutes,
        ErrorBoundary,
      },
    ],
  },

  {
    path: 'tutor',
    element: <RequireAuth allowed={['Tutor']} />,
    children: [
      {
        element: (
          <UserChannelProvider>
            <TutorDashboard />
          </UserChannelProvider>
        ),
        children: TutorRoutes,
      },
    ],
  },

  {
    path: 'admin',
    element: <RequireAuth allowed={['Admin']} />,
    children: [
      {
        element: (
          <UserChannelProvider>
            <AdminDashboard />
          </UserChannelProvider>
        ),
        children: AdminRoutes,
        ErrorBoundary,
      },
    ],
  },

  {
    path: 'plasmic-host',
    element: <PlasmicCanvasHost />,
  },

  {
    path: '*',
    element: <PageNotFound />,
  },
]

export function ErrorELement() {
  const path = useLocation().pathname
  useEffect(() => {
    if (path.includes('slots')) {
      throw new Error('Something went wrong')
    }
  }, [path])

  useEffect(() => {
    if (path.includes('students')) {
      throw new Error('chunk')
    }
  }, [path])

  return null
}

function HideInitialLoadingSpinner() {
  const { hideSpinner } = useOverlaySpinner()
  useEffect(() => {
    hideSpinner()
  }, [hideSpinner])
  return null
}

const _createBrowserRouter = isProduction
  ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  : createBrowserRouter

function RouterRootElement() {
  return (
    <>
      <HideInitialLoadingSpinner />
      <LanguageProvider>
        {isProduction && <HotjarProvider />}
        {isProduction && <ChatProvider />}
        <QuickLogin>
          <Outlet />
        </QuickLogin>
      </LanguageProvider>
      <ToastContainer
        style={{
          zIndex: '99999',
        }}
      />
    </>
  )
}
export const router = _createBrowserRouter([
  {
    path: '',
    ErrorBoundary,
    element: <RouterRootElement />,
    children: routes,
  },
])
